import {FC} from 'react'

import { Link } from 'react-router-dom'

const HeroSection: FC = () => {
    return (
            <div className="mx-auto ">
                <div className="flex flex-wrap justify-center items-center">
                    <div className="w-full md:w-1/2 ">
                        <div className="flex flex-wrap  mb-2 justify-center">
                            <h1 className="mb-6 text-2xl md:text-4xl lg:text-6xl font-bold font-heading md:max-w-xl flex text-center " data-config-id="auto-txt-12-1">Intelligent Visual Communication Platform</h1>
                            <p className="mb-2 text-lg text-gray-900 font-medium flex text-center">Orchard.works gives you the ability to use visual arrtefacts such as animation and infographics to enable effective communication, training and education</p>


                        </div>

                        <div className="flex flex-wrap  mb-2 justify-center">
                            <div className="w-full md:w-auto p-2.5 flex justify-center">
                                <div className="block flex justify-center">
                                    <Link to="/register" >
                                        < button className="py-4 px-6 w-full text-white font-semibold border border-orange-700 rounded-xl focus:ring focus:ring-orange-300 bg-orange-600 hover:bg-orange-700 transition ease-in-out duration-200" type="button">Create an account</button>
                                    </Link>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="w-full md:w-1/2 p-8">
                        <img className="transform hover:-translate-y-16 transition ease-in-out duration-1000" src="../image@2x.png" alt="" data-config-id="auto-img-6-1" />
                    </div>
                </div>
            </div>
    )
}

export default HeroSection