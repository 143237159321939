// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Router,
  useRoutes,
} from 'react-router-dom';

import { FC } from 'react';
import Home from './pages/Home';
import Index from "./pages/dashboard/Index";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardLayout from "./layouts/DashboardLayout";
import WebsiteLayout from "./layouts/WebsiteLayout";
import ChannelIndex from './pages/dashboard/channel/ChannelIndex';
import Channel from './pages/dashboard/channel/Channel';
import AddChannel from './pages/dashboard/channel/AddChannel';
import AddOrg from './pages/dashboard/organisation/AddOrg';
import InviteUsers from './pages/dashboard/channel/InviteUsers';
import UserAllotment from './pages/dashboard/channel/UserAllotment';
import Order from './pages/dashboard/organisation/Order';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './redux/reducers/authReducer';
import { Provider } from 'react-redux';

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
})


const App: FC = () => {

  let element = useRoutes([

    // Main website routes
    {
      path: '/',
      element: <WebsiteLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
      ]
    },

    // Private Routes
    {
      path: '/account',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Index /> },
        { path: 'channels', element: <ChannelIndex /> },
        { path: 'channel', element: <Channel /> },
        { path: 'channel-add', element: <AddChannel /> },
        { path: 'invite-users', element: <InviteUsers /> },
        { path: 'org-add', element: <AddOrg /> },
      ]
    },


    // Private Routes
    {
      path: '/account',
      element: <WebsiteLayout />,
      children: [
        { path: 'user-allotment', element: <UserAllotment /> },
        { path: 'order', element: <Order /> },
      ]
    }

    // RoleSpecific Rputes
  ]);

  return (
    <Provider store={store}>

      {element}

    </Provider>
  );

}

export default App;
