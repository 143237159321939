import {useState} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const AddOrg = () => {

    const [errors, setErrors] = useState({
        name: '',
        description: '',
        country: '',
        address: '',
        size: '',
    });

    const [form, setForm] = useState({
        name: '',
        description: '',
        country: '',
        address: '',
        size: '',
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const updateForm = (key:string, value:string) => {

        let data:any = form;
        data[key] = value;
        setForm(data);

    }

    const addOrg = (e: React.MouseEvent<HTMLButtonElement>) => {
        setErrors({
            name: '',
            description: '',
            country: '',
            address: '',
            size: '',
        });
        e.preventDefault();
        setLoading(true);

        const validationErrors = {
            name: '',
            description: '',
            country: '',
            address: '',
            size: '',
        }
       
        if (form.name === '') {
            validationErrors.name = "Please enter organisation name.";
        }


        if (form.description === '') {
            validationErrors.description = "Please enter organisation description";
        }

        if (form.country === '') {
            validationErrors.country = "Please enter organisation country";
        }

        if (form.address === '') {
            validationErrors.address = "Please enter organisation address";
        }
        
        if (form.size === '') {
            validationErrors.size = "Please select business size";
        }
        

        setTimeout(() => {
            if(!validationErrors.name && !validationErrors.description && !validationErrors.country && !validationErrors.address && !validationErrors.size) { 
                navigate("/account/user-allotment");
            }
            setErrors(validationErrors);
            setLoading(false);
        }, 2000);
       
    }

    return (

       
    <div className="block p-6 w-100 bg-white  rounded-lg shadow add-channel-wrapper channel-form">

        <div className='flex justify-between'>
            <div>
                <h5 className="text-2xl font-bold tracking-tight">Create an organisation to get started</h5>
                <p>Organisation is a hub for all your channels</p>
            </div>
            <Link to="/account/channels" className='close-icon'>
                <i className="ri-close-line"></i>
            </Link>
        </div>

        <form className="space-y-6">
            <h5 className="text-xl font-medium text-gray-900 dark:text-white">Sign in to our platform</h5>
            <div className="w-full mb-6 md:mb-0">
                <input type="text"  onChange={e => updateForm('name', e.target.value)} className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" placeholder="Name" />
                {errors.name && <p className="mt-2 mb-2 text-sm text-red-600 dark:text-red-500"> {errors.name}</p> }
            </div>

            <div className="w-full mb-6 md:mb-0">
            <textarea id="message" rows={4}  onChange={e => updateForm('description', e.target.value)} className="block p-2.5 w-full" placeholder="Description"></textarea>
                {errors.description && <p className="mt-2 mb-2 text-sm text-red-600 dark:text-red-500"> {errors.description}</p> }
            </div>

            <div className="w-full mb-6 md:mb-0">
                <select id="countries" className="block w-full p-2.5"  onChange={e => updateForm('country', e.target.value)}>
                    <option selected>Country</option>
                    <option value="ZA">South Africa</option>
                </select>
                {errors.country && <p className="mt-2 mb-2 text-sm text-red-600 dark:text-red-500"> {errors.country}</p> }
            </div>

            <div className="w-full mb-6 md:mb-0">
                <input type="text"  onChange={e => updateForm('address', e.target.value)} className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" placeholder="Business Address / Location" />
                {errors.name && <p className="mt-2 mb-2 text-sm text-red-600 dark:text-red-500"> {errors.address}</p> }
            </div>

            <div className="w-full mb-6 md:mb-0">
                <select id="countries" className="block w-full p-2.5"  onChange={e => updateForm('size', e.target.value)}>
                    <option selected>Organisation Size</option>
                    <option value="1">1-50</option>
                    <option value="1">51-100</option>
                    <option value="1">100-300</option>
                    <option value="1">300-500</option>
                    <option value="1">1001+</option>
                </select>
                {errors.size && <p className="mt-2 mb-2 text-sm text-red-600 dark:text-red-500"> {errors.size}</p> }
            </div>


            <div className='flex justify-end'>

            <button onClick={addOrg} disabled={loading} type="submit" className="btn-dark btn btn-rounded px-3.5 py-2.5 text-sm font-semibold text-white">
                {loading &&   <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#fff"></path>
                                            </svg>}
                                    {loading? "Submitting..." : "Create Organization"}
                    </button>

            </div>
        
        </form>


</div>



    )
}

export default AddOrg;