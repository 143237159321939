import {FC} from 'react'
import ChannelCard from '../../../components/ChannelCard';
import { Link } from 'react-router-dom';
import ChannelStats from '../../../components/ChannelStats';
const ChannelIndex: FC = () => {
    return (

        <div className='w-100 channel-main rounded'>
            <div className="grid grid-cols-2 gap-6 p-12 h-auto">
                <ChannelCard>
                    <div className="px-6 py-4 card-content">
                        <div>
                            <div className="font-bold text-xl mb-2 title">Username’s org</div>
                            <Link to="/account/channel" className="text-gray-700 text-base">
                            View more
                            </Link>
                        </div>
                        <div>
                            <ChannelStats/>
                        </div>
                    </div>
                    
                </ChannelCard>

                <ChannelCard backgroundColor='#B0B6BA'>
                    <div className="px-6 py-4 card-content">
                        <div>
                            <div className="font-bold text-xl mb-2 title">Add new org</div>
                            <Link to="/account/org-add" className="text-gray-700 text-base">
                            View more
                            </Link>
                        </div>
                        <div>
                            <ChannelStats/>
                        </div>
                    </div>
                    
                </ChannelCard>

                

            </div>
        </div>

    )
}

export default ChannelIndex