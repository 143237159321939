import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Email from './tabs/Email';
import Share from './tabs/Share';
import Import from './tabs/Import';

const InviteUsers = () => {
    const [selected, setSelected] = useState('email');
    const defalutClass:string = 'tab-btn btn-dark btn-rounded mr-3 btn px-3.5 py-2.5 text-sm font-semibold text-white';

    const navigate = useNavigate();
    
    const nextStep = () => {
        navigate("/account/channel");
    }
    

    return (

       
    <div className="block p-6 w-100 bg-white  rounded-lg shadow add-channel-wrapper channel-form">

        <div className='flex justify-between'>
            <div className='header'>
                <h5 className="text-2xl font-bold tracking-tight">Invite Users</h5>
                <div className='inline-block mt-5 mb-5'>
                    <button className={` ${defalutClass} ${selected === 'email' ? 'active' : ''}`}  onClick={()=>setSelected('email')}>Email</button>
                    <button className={` ${defalutClass} ${selected === 'import' ? 'active' : ''}`} onClick={()=>setSelected('import')}>Import </button>
                    <button className={` ${defalutClass} ${selected === 'share' ? 'active' : ''}`}  onClick={()=>setSelected('share')}>Share</button>
                </div>
            </div>
            <Link to="/account/channels" className='close-icon'>
                <i className="ri-close-line"></i>
            </Link>
        </div>

        {selected === 'email' && <Email />}
        {selected === 'import' && <Import />}
        {selected === 'share' && <Share />}

        <div className='flex justify-between mt-5'>
            <div className='channel-steps'>
                <Link to="#" className='step'>
                    <div className='number'>1</div>
                    <div className='text'>Create a channel</div>
                    <i className="ri-arrow-right-s-line"></i>
                </Link>

                <Link to="#" className='step active'>
                    <div className='number'>2</div>
                    <div className='text'>Invite Users</div>
                </Link>

            </div>
            { selected !== 'share' &&
            <button onClick={nextStep}  type="submit" className="btn-dark btn btn-rounded px-3.5 py-2.5 text-sm font-semibold text-white">
                Invite
            </button>
            }
        </div>
    </div>



    )
}

export default InviteUsers;