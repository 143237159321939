import {FC} from 'react'

export type Option = {
    title: string,
    text: string,
    cost: string,
    discount?:string,
    discountClass?: string,
    features: Feature[]
};

export type Feature = {
    name: string,
    value: string
};

export type Props = {
    option: Option,
    setOption?: Function,
    selected?: string,
    buttonText?:string,
    terms?:string,
};

const UserAllotmentCard: FC<Props> = ({ 
    option, setOption, selected, buttonText, terms
}) => {

    const selectOption = (selectedOption: string) => {
        setOption?.(selectedOption);
    }

    return (
            <div className={`allotment-card ${selected === option.title ? 'selected':''}`}>
                <div className='text-center'>
                    <h3>{option.title}</h3>
                    <p>{option.text}</p>
                    {option.discount && <div className={option.discountClass+ ' discount'}>{option.discount}</div>}
                </div>
                <div className='middle'>
                    <h3 className='text-center mb-3'>{option.cost}</h3>
                    {option.features.map(feature =>
                        <div className='grid grid-cols-2 gap-1 features'>
                            <div className='col-span-1 name'>
                                {feature.name}
                            </div>
                            <div className='col-span-1 text-right value'>
                                {feature.value}
                            </div>
                        </div>
                    )}
                </div>

                    <button onClick={e=> selectOption(option.title)} type="button" className="btn-dark btn-full btn btn-rounded px-3.5 py-2.5 text-sm font-semibold text-white">
                    {buttonText?buttonText:'Get Started'}
                    </button>
                    {terms && <p  className='text-center'>{terms}</p>}
            </div>
    )
}

export default UserAllotmentCard