import {FC} from 'react'

import { Link } from 'react-router-dom';
import BulletinCard from './BulletinCard';

const Bulletin: FC = () => {

    const bulletins = [
        {
            avatar: '/',
            name: 'Username',
            channel: 'Channel Name',
            date: 'Channel Name',
            text: 'Channel Name',
            stats: {
                likes: 200,
                replies: 500,
                reposts: 400
            }
        }
    ]
    return (
            <div className="mx-auto ">
                {bulletins.map(bulletin => (
                    <BulletinCard bulletin={bulletin}></BulletinCard>
                ))}
            </div>
    )
}

export default Bulletin