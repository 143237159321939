import {FC} from 'react'

import UserCard from './UserCard';

const Users: FC = () => {

    const users = [
        {
            name: 'User Name',
            channel: 'Channel Name'
        }
    ]
    return (
            <div className="mx-auto ">
                {users.map(user => (
                    <UserCard user={user}></UserCard>
                ))}
            </div>
    )
}

export default Users