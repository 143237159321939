import {FC} from 'react'

import { Link } from 'react-router-dom'

const Sidebar: FC = () => {
    return (
            <>
                <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
                </button>

                <aside id="default-sidebar" className="side-nav p-4 pl-0 fixed top-0 left-0 z-40 w-30 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                    <div className="h-full px-4 dashboard-sidebar py-4 overflow-y-auto bg-light-50  rounded  border-2">
                        <ul className="space-y-2 font-medium">
                            <li>

                                <Link to="/" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                <img className='logo' src="../orchard-logo@2x.png" alt="" />

                                    <span >Home</span>
                                </Link>

                                <Link to="#" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                <svg id="Icon_Series" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                    <rect id="Guide" width="32" height="32" opacity="0"/>
                                    <path id="Icon_material-movie-creation" data-name="Icon material-movie-creation" d="M22.2,6l2.4,4.8H21L18.6,6H16.2l2.4,4.8H15L12.6,6H10.2l2.4,4.8H9L6.6,6H5.4A2.4,2.4,0,0,0,3.012,8.4L3,22.8a2.407,2.407,0,0,0,2.4,2.4H24.6A2.407,2.407,0,0,0,27,22.8V6Z" transform="translate(1 0.56)"/>
                                </svg>

                                <span >Series</span>
                                </Link>

                                <Link to="/account/channels" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                    <svg id="Icon_Channels" xmlns="http://www.w3.org/2000/svg" width="32.75" height="32.201" viewBox="0 0 32.75 32.201">
                                        <rect id="Guide" width="32.75" height="32.201" opacity="0"/>
                                        <path id="Union_2" data-name="Union 2" d="M6.545,18.2a.716.716,0,1,1,0-1.432H18.2a.716.716,0,0,1,0,1.432ZM2.368,15.752A2.373,2.373,0,0,1,0,13.384V2.367A2.372,2.372,0,0,1,2.368,0H22.378A2.378,2.378,0,0,1,24.75,2.367V13.384a2.372,2.372,0,0,1-2.367,2.368ZM1.432,2.367V13.379a.936.936,0,0,0,.936.935H22.378a.935.935,0,0,0,.935-.935h.005V2.367a.936.936,0,0,0-.936-.936H2.368A.936.936,0,0,0,1.432,2.367ZM3.17,13.19a.616.616,0,0,1-.613-.614V3.17a.616.616,0,0,1,.613-.614H21.576a.615.615,0,0,1,.613.614v9.407a.615.615,0,0,1-.613.614Z" transform="translate(4 7)"/>
                                    </svg>

                                    <span >Channels</span>
                                </Link>

                                <Link to="#" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                        <svg id="Icon_Groups" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                        <rect id="Guide" width="32" height="32" opacity="0"/>
                                        <path id="Icon_material-group" data-name="Icon material-group" d="M23.318,16.071a4.286,4.286,0,1,0-4.364-4.286A4.307,4.307,0,0,0,23.318,16.071Zm-11.636,0a4.286,4.286,0,1,0-4.364-4.286A4.307,4.307,0,0,0,11.682,16.071Zm0,2.857c-3.389,0-10.182,1.671-10.182,5V27.5H21.864V23.929C21.864,20.6,15.071,18.929,11.682,18.929Zm11.636,0c-.422,0-.9.029-1.411.071a6.007,6.007,0,0,1,2.865,4.929V27.5H33.5V23.929C33.5,20.6,26.707,18.929,23.318,18.929Z" transform="translate(-1.5 -0.5)"/>
                                    </svg>
                                    <span >Groups</span>
                                </Link>

                                <Link to="#" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
  <g id="Icon_Latest" transform="translate(0 356.973)">
    <rect id="Rectangle_724" data-name="Rectangle 724" width="42" height="42" transform="translate(0 -356.973)" fill="red" opacity="0.002"/>
    <g id="Icon_Latest-2" data-name="Icon_Latest" transform="translate(4.848 -352.02)">
      <path id="Path_3232" data-name="Path 3232" d="M4746.651-592.089c1.052,0,1.051-1.224,1.051-1.224a12.144,12.144,0,0,1,2.768-4.828,11.8,11.8,0,0,1,4.93-2.471,1.129,1.129,0,0,0,.785-1.048,1.014,1.014,0,0,0-.785-.983,15.761,15.761,0,0,1-5.4-2.994,8.985,8.985,0,0,1-2.3-4.628,1.232,1.232,0,0,0-1.159-.964,1.03,1.03,0,0,0-1.021.964,15.324,15.324,0,0,1-3.039,5.237,9.961,9.961,0,0,1-4.719,2.385,1,1,0,0,0-.733.983c0,.8,1.065,1.048,1.065,1.048a14.233,14.233,0,0,1,4.869,2.788,11.055,11.055,0,0,1,2.556,4.511S4745.6-592.089,4746.651-592.089Z" transform="translate(-4737.032 611.23)"/>
      <path id="Path_3233" data-name="Path 3233" d="M4744.524-596.323c.819,0,.818-.953.818-.953a9.456,9.456,0,0,1,2.156-3.76,9.183,9.183,0,0,1,3.839-1.924.879.879,0,0,0,.611-.817.79.79,0,0,0-.611-.765,12.27,12.27,0,0,1-4.2-2.332,7,7,0,0,1-1.792-3.6.96.96,0,0,0-.9-.751.8.8,0,0,0-.8.751,11.93,11.93,0,0,1-2.366,4.079,7.759,7.759,0,0,1-3.675,1.857.781.781,0,0,0-.571.765c0,.625.83.817.83.817a11.083,11.083,0,0,1,3.792,2.171,8.608,8.608,0,0,1,1.99,3.513S4743.7-596.323,4744.524-596.323Z" transform="translate(-4719.949 621.02)"/>
      <path id="Path_3234" data-name="Path 3234" d="M4742.548-600.252c.6,0,.6-.7.6-.7a6.963,6.963,0,0,1,1.588-2.769,6.762,6.762,0,0,1,2.828-1.417.647.647,0,0,0,.45-.6.582.582,0,0,0-.45-.564,9.035,9.035,0,0,1-3.1-1.717,5.15,5.15,0,0,1-1.32-2.654.706.706,0,0,0-.664-.553.592.592,0,0,0-.586.553,8.789,8.789,0,0,1-1.743,3,5.711,5.711,0,0,1-2.706,1.368.575.575,0,0,0-.42.564c0,.46.611.6.611.6a8.161,8.161,0,0,1,2.792,1.6,6.336,6.336,0,0,1,1.466,2.587S4741.945-600.252,4742.548-600.252Z" transform="translate(-4730.613 632.019)"/>
    </g>
  </g>
</svg>


                                <span >New</span>
                                </Link>



                                <Link to="#" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                <svg id="Icon_Latest_and_Popular" data-name="Icon_Latest and Popular" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <rect id="Guide" width="32" height="32" fill="#fff" opacity="0"/>
  <path id="FontAwsome_fire-alt_" data-name="FontAwsome (fire-alt)" d="M18.959,3a35,35,0,0,0-3.294,3.514A45.462,45.462,0,0,0,9.844,0C4.086,5.342,0,12.3,0,16.5A13.316,13.316,0,0,0,13.125,30,13.316,13.316,0,0,0,26.25,16.5c0-3.121-3.046-9.559-7.291-13.5ZM17.818,22.96a7.875,7.875,0,0,1-4.525,1.415c-4.228,0-7.668-2.8-7.668-7.339,0-2.262,1.424-4.256,4.265-7.661.406.468,5.791,7.344,5.791,7.344L19.116,12.8c.243.4.463.794.66,1.17a6.989,6.989,0,0,1-1.959,8.989Z" transform="translate(2.375 1)"/>
</svg>


                                <span >Popular</span>
                                </Link>



                                <Link to="#" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                <svg id="Icon_Recent" xmlns="http://www.w3.org/2000/svg" width="47" height="50" viewBox="0 0 47 50">
  <g id="Icon_Recent-2" data-name="Icon_Recent" transform="translate(11 12)">
    <path id="Icon_awesome-clock" data-name="Icon awesome-clock" d="M13.563.563a13,13,0,1,0,13,13A13,13,0,0,0,13.563.563Zm2.993,18.352-4.623-3.36a.633.633,0,0,1-.257-.508V6.224a.631.631,0,0,1,.629-.629h2.516a.631.631,0,0,1,.629.629v7.218l3.329,2.422a.629.629,0,0,1,.136.881l-1.478,2.034A.633.633,0,0,1,16.556,18.915Z" transform="translate(-0.563 -0.563)"/>
  </g>
  <rect id="Guide" width="47" height="50" fill="#faa" opacity="0"/>
</svg>


                                <span >Recent</span>
                                </Link>


                                <Link to="#" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                <svg id="Icon_Search" xmlns="http://www.w3.org/2000/svg" width="32" height="29.2" viewBox="0 0 32 29.2">
  <rect id="Guide" width="32" height="29.2" fill="#fff" opacity="0"/>
  <path id="search" d="M12.234,0a9.09,9.09,0,0,0-9.2,8.977,8.784,8.784,0,0,0,1.418,4.792l-.02.008-4,3.905L3.832,21l4.146-4.048c0-.006,0-.012,0-.017a9.338,9.338,0,0,0,4.26,1.019,9.09,9.09,0,0,0,9.2-8.977A9.089,9.089,0,0,0,12.234,0Zm0,3.988a5.051,5.051,0,0,1,5.111,4.989,5.113,5.113,0,0,1-10.222,0A5.051,5.051,0,0,1,12.234,3.988Z" transform="translate(5.567 4)"/>
</svg>


                                <span >Search</span>
                                </Link>


                                <Link to="#" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                <svg id="Icon_Admin" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <rect id="Guide" width="32" height="32" fill="#fff" opacity="0"/>
  <path id="Icon_ionic-ios-settings" data-name="Icon ionic-ios-settings" d="M29.271,18A3.474,3.474,0,0,1,31.5,14.759a13.772,13.772,0,0,0-1.666-4.015,3.521,3.521,0,0,1-1.413.3,3.467,3.467,0,0,1-3.171-4.88A13.73,13.73,0,0,0,21.241,4.5a3.471,3.471,0,0,1-6.483,0,13.772,13.772,0,0,0-4.015,1.666,3.467,3.467,0,0,1-3.171,4.88,3.406,3.406,0,0,1-1.413-.3A14.076,14.076,0,0,0,4.5,14.766a3.473,3.473,0,0,1,.007,6.483,13.772,13.772,0,0,0,1.666,4.015,3.468,3.468,0,0,1,4.577,4.577,13.852,13.852,0,0,0,4.015,1.666,3.465,3.465,0,0,1,6.469,0,13.772,13.772,0,0,0,4.015-1.666,3.472,3.472,0,0,1,4.577-4.577,13.852,13.852,0,0,0,1.666-4.015A3.491,3.491,0,0,1,29.271,18ZM18.063,23.618a5.625,5.625,0,1,1,5.625-5.625A5.623,5.623,0,0,1,18.063,23.618Z" transform="translate(-2 -2.004)"/>
</svg>


                                <span >Settings</span>
                                </Link>


                                <Link to="#" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                <svg id="Icon_Help" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <rect id="Guide" width="32" height="32" fill="#fff" opacity="0"/>
  <path id="Icon_ionic-md-help-circle" data-name="Icon ionic-md-help-circle" d="M16.375,3.375a13,13,0,1,0,13,13A13.006,13.006,0,0,0,16.375,3.375Zm1.313,20.812H15.062V21.563h2.625Zm-.013-3.937h-2.6c0-4.187,3.9-3.888,3.9-6.488a2.611,2.611,0,0,0-2.6-2.606,2.638,2.638,0,0,0-2.6,2.594h-2.6a5.2,5.2,0,0,1,10.4.006C21.575,17.006,17.675,17.375,17.675,20.25Z" transform="translate(-0.375 -0.375)"/>
</svg>



                                <span >Help</span>
                                </Link>

                                <Link to="#" className="sidebar-link flex items-center p-2 text-gray-900 rounded-lg text-dark group">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Icon_Orgs" transform="translate(-6.787 -39)">
    <rect id="Guide" width="32" height="32" transform="translate(6.787 39)" fill="#fff" opacity="0"/>
    <path id="Icon_ionic-md-link" data-name="Icon ionic-md-link" d="M4.722,16.625A4.03,4.03,0,0,1,8.75,12.6h5.107V10.125H8.75a6.5,6.5,0,1,0,0,13h5.107V20.653H8.75A4.03,4.03,0,0,1,4.722,16.625ZM9.679,17.9H20.821V15.348H9.679ZM21.75,10.125H16.643V12.6H21.75a4.028,4.028,0,1,1,0,8.055H16.643v2.472H21.75a6.5,6.5,0,1,0,0-13Z" transform="translate(7.537 38.875)"/>
  </g>
</svg>


                                <span >Orgs</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </aside>
            </>
    )
}

export default Sidebar