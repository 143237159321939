import {FC} from 'react'

import { Link } from 'react-router-dom';
import SchedulingCard from './SchedulingCard';

const Scheduling: FC = () => {

    const schedules = [
        {
            avatar: '/',
            name: 'Username',
            channel: 'Channel Name',
            date: 'Channel Name',
            text: 'Channel Name',
            stats: {
                likes: 200,
                reposts: 500,
            },
            event: {
                type: 'Event',
                time: 'Date'
            }
        }
    ]
    return (
            <div className="mx-auto ">
                {schedules.map(schedule => (
                    <SchedulingCard schedule={schedule}></SchedulingCard>
                ))}
            </div>
    )
}

export default Scheduling;