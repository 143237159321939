import {FC} from 'react'
import { Link } from 'react-router-dom'

const Navbar: FC = () => {
    
    const links = [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "About",
            path: "/about"
        },
        {
            name: "Tutorial",
            path: "/tutorial"
        },
        {
            name: "Pricing",
            path: "/pricing"
        },
        {
            name: "Login",
            path: "/login"
        },
    ]

    return (
        <section data-section-id="3" data-share="" data-category="headers" data-component-id="44d0b9e2_01_awz" className="nav bg-blueGray-50">
            <div className="container mx-auto overflow-hidden">
                <div className="flex items-center justify-between px-4 py-5 bg-blueGray-50"  >
                    <div className="w-auto">
                        <div className="flex flex-wrap items-center">


                            <div className="w-[180px]">

                                <Link to="/">
                                    <div className="grid grid-cols-2 content-start">
                                        <img src="../orchard-logo@2x.png" alt="" />
                                        <h2 className='flex items-center font-bold text-2xl'>Orchard.Works</h2>
                                    </div>


                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-auto">
                        <div className="flex flex-wrap items-center">
                            <div className="w-auto hidden lg:block">
                                <ul className="flex items-center mr-16">
                                    {links.map(link => (
                                        <>
                                         {link.path === '/login'? 
                                                <Link to={link.path}className="btn-dark btn rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{link.name}</Link> : 
                                                <Link to={link.path} key={link.name}>
                                                        <li className="mr-9 font-medium hover:text-gray-700">{link.name}</li>
                                                </Link>
                                            }
                                       </>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50">
                    <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"></div>
                    <nav className="relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto" >
                        <div className="flex flex-wrap justify-between h-full">
                            <div className="w-full">
                                <div className="flex items-center justify-between -m-2">
                                    <div className="w-auto p-2">
                                        <a className="inline-block" href="#!">
                                            <img src="flaro-assets/logos/flaro-logo-black.svg" alt="" />
                                        </a>
                                    </div>
                                    <div className="w-auto p-2">
                                        <a className="navbar-burger" href="#!">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                <path d="M6 18L18 6M6 6L18 18" stroke="#!111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center py-16 w-full">
                                <ul>
                                    <li className="nav-link mb-12"><a className="font-medium hover:text-gray-700" href="#!" data-config-id="auto-txt-6-1">Features</a></li>
                                    <li className="nav-link mb-12"><a className="font-medium hover:text-gray-700" href="#!" data-config-id="auto-txt-7-1">Solutions</a></li>
                                    <li className="nav-link  mb-12"><a className="font-medium hover:text-gray-700" href="#!" data-config-id="auto-txt-8-1">Resources</a></li>
                                    <li><a className="btn font-medium hover:text-gray-700" href="#!" data-config-id="auto-txt-9-1">Pricing</a></li>
                                </ul>
                            </div>
                            <div className="flex flex-col justify-end w-full pb-8">
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div className="block">
                                            <button className="py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button" data-config-id="auto-txt-10-1">Try 14 Days Free Trial</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

        </section>
    )
}

export default Navbar