import {FC, useState} from 'react'
import { Link } from 'react-router-dom';

const OrgChannelCard: FC = () => {
    const [showDropdown, setShowDropdown] = useState('zzz');

    const handleDropdown = () => {
        if(showDropdown === 'show') {
            setShowDropdown('');
        }
        else {
            setShowDropdown('show');
        }
    }

    return (
            
            <div className="org-channel-card">
                <div className='thumbnail'>
                    <img className="rounded-t-lg" src="https://flowbite.com/docs/images/blog/image-1.jpg" alt="" />
                    <button id="dropdownButton" data-dropdown-toggle="dropdown" className="" type="button" onClick={handleDropdown}>
                        <span className="sr-only">Open dropdown</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                            <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                        </svg>
                    </button>
                    <div id="dropdown" className={showDropdown+" dropdown z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"}>
                        <ul className="py-2" aria-labelledby="dropdownButton">
                        <li>
                        <i className="ri-add-line"></i> <Link to="#">Add Content to channel</Link>
                        </li>
                        <li>
                        <i className="ri-user-add-fill"></i> <Link to="#">Invite</Link>
                        </li>
                        <li>
                        <i className="ri-share-fill"></i> <Link to="#" >Temp Access</Link>
                        </li>

                        <li>
                        <i className="ri-megaphone-fill"></i><Link to="#" >Publicity</Link>
                        </li>

                        </ul>
                    </div>
                </div>
                <div className="p-5 content">
                    <Link to="#">
                        <b>Sample Channel</b>
                    </Link>
                    <p className="mb-2 mt-2 font-normal text-gray-700 dark:text-gray-400">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                    <div className='stats flex justify-end align-center'>
                        <div className='inline-block mr-3'>
                        <i className="ri-film-line mr-1"></i>
                            <b>22</b>
                        </div>

                        <div className='inline-block'>
                        <i className="ri-map-pin-user-fill mr-1"></i>
                            <b>3</b>
                        </div>
                    </div>
                </div>
                
            </div>

    )
}

export default OrgChannelCard;