import {FC} from 'react'


export type Props = {
    backgroundColor?: string,
    children: React.ReactNode
};

const ChannelCard: FC<Props> = ({ backgroundColor, children}) => {
    return (

        <div>
           <div className="rounded overflow-hidden shadow-lg channel-card" style={{backgroundColor: backgroundColor?backgroundColor:'#58AAFF'}}>
               {children}
            </div>
        </div>

    )
}

export default ChannelCard;