import {FC} from 'react'

import MessagingCard from './MessagingCard';

const Messaging: FC = () => {

    const messages = [
        {
            avatar: '/',
            channel: 'Channel Name',
            date: 'Channel Name',
            text: 'Channel Name',
            count: 3
        }
    ]
    return (
            <div className="mx-auto ">
                {messages.map(message => (
                    <MessagingCard message={message}></MessagingCard>
                ))}
            </div>
    )
}

export default Messaging