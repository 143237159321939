import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import UserAllotmentCard from '../../../components/UserAllotmentCard';
import { useNavigate } from "react-router-dom";

const Order = () => {

    const navigate = useNavigate();

    const navigateOrg = () => {
        navigate("/account/channel");

    }

    const order = {
       
            title: '51 - 100 Seats',
            text: 'Ideal for individuals or teams just getting started with training.',
            cost: '$0.90 per Seat / 0.90 per 100mb',
            discount: '10% Off',
            discountClass: 'danger',
            features: [
                {
                    name: 'Period',
                    value: 'Monthly'
                },

                {
                    name: 'Max Channels',
                    value: '50'
                },

                {
                    name: 'Storage',
                    value: '100mb'
                }
            ]

        }
    return (

       
    <div className=" p-6 w-100 bg-white order-page">

        <div className='flex justify-between mb-4'>
            <div className='header'>
                <h5 className="text-2xl mb-4 font-bold tracking-tight text-center">Your Order</h5>
                <p className='text-center'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore</p>
            </div>
        </div>


        <UserAllotmentCard 
        buttonText='Continue to payment'
        setOption={navigateOrg}
        terms='By placing my secure order, I agree to Adobe’s subscription and cancellation terms and Terms of Use.'
        option={order} />



</div>



    )
}

export default Order;