import {FC} from 'react'

export type User = {
    name: string,
    channel: string,
};

export type Props = {
    user: User
};

const BulletinCard: FC<Props> = ({ 
    user
}) => {
    return (
            <div className="user-card widget-card">
                <div className='avatar'>
                    <img src="https://static.vecteezy.com/system/resources/previews/009/734/564/original/default-avatar-profile-icon-of-social-media-user-vector.jpg"/>
                </div>
                <div className='content'>
                    <div className='title'>
                        <span>User Name</span>
                        <span>Channel Name</span>
                    </div>
                    <div className='button-wrapper'>
                        <button className='btn-dark btn btn-sm rounded-lg bg-indigo-600 px-2 py-1 text-white'>Related Item</button>
                        <button className='btn-primary btn btn-sm rounded-lg bg-indigo-600 px-2 py-1 text-white'>Related Item</button>
                    </div>
                    
                </div>
                <div className='end'>
                    ...
                </div>
            </div>
    )
}

export default BulletinCard