import {FC} from 'react'

import { Link } from 'react-router-dom'


export type Event = {
    name: string,
    time: string,
};


export type Bulletin = {
    avatar: string,
    name: string,
    channel: string,
    date: string,
    text: string,
    event?: Event,
    stats: {
        likes: number,
        replies: number,
        reposts: number,
    }
};


export type Props = {
    bulletin: Bulletin
};

const BulletinCard: FC<Props> = ({ 
    bulletin
}) => {
    return (
            <div className="bulletin-card widget-card">
                <div className='card-wrappwer'>
                    <div className='avatar'>
                        <img src="https://static.vecteezy.com/system/resources/previews/009/734/564/original/default-avatar-profile-icon-of-social-media-user-vector.jpg"/>
                    </div>
                    <div className='content'>
                        <div className='title'>
                            <b>{bulletin.name}</b>
                            <span>Channel Name</span>
                            <span>1hr ago</span>
                        </div>
                        <div>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor diam nonumy …</p>
                        </div>
                        <div className='grid grid-cols-4 gap-4 stats'>
                            <div className='col-span-1'><i className="ri-heart-fill"></i> 609</div>
                            <div className='col-span-1'><i className="ri-question-answer-fill"></i> 120</div>
                            <div className='col-span-1'> <i className="ri-reply-fill"></i> 120</div>
                            <div className='col-span-1'><i className="ri-send-plane-fill"></i></div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default BulletinCard