import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/main.scss';
import 'remixicon/fonts/remixicon.css'
import App from './App';
// import { store } from './app/store'
// import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
      <BrowserRouter>
        <div >
          <App />
        </div>
      </BrowserRouter>
     
    {/* </Provider> */}


  </React.StrictMode>
);

