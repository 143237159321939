import {FC} from 'react';

import {
    Outlet,
  } from 'react-router-dom';
import SidebarWidget from '../components/SidebarWidget';
import Sidebar from '../components/Sidebar';

const DashboardLayout: FC = () => {
    return (
        <div className="dashboard-wrapper h-full">
            <Sidebar/>
            <main>
                <div className="p-5 sm:ml-40">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-3">
                            <Outlet/>
                        </div>
                        <div className="flex justify-center h-screen">
                            <SidebarWidget/>
                        </div>
                    </div>
                </div>
            </main>
        </div>
);
}

export default DashboardLayout;