import {FC, useState} from 'react'
import { Link } from 'react-router-dom'
import Bulletin from './bulletin/Bulletin';
import Messaging from './messaging/Messaging';
import Scheduling from './scheduling/Scheduling';
import Users from './users/Users';

const SidebarWidget: FC = () => {
    
    const [activeTab, setActiveTab] = useState('bulletin');

    const handleTabChange = (tab:string) => {
        setActiveTab(tab);
    }

    return (
        <section className="sidebar-widget">
             <div className="grid grid-cols-4 gap-4 menu-items">
                <div className={`col-span-1 menu-item ${activeTab === 'bulletin' ? 'active' : ''}`} onClick={()=> handleTabChange('bulletin')}>
                    <i className="ri-newspaper-line"></i>
                    <span>Bulletin</span>
                </div>
                <div className={`col-span-1 menu-item ${activeTab === 'messaging' ? 'active' : ''}`} onClick={()=> handleTabChange('messaging')}>
                <i className="ri-user-fill"></i>
                    <span>Messaging</span>
                </div>
                <div className={`col-span-1 menu-item ${activeTab === 'scheduling' ? 'active' : ''}`} onClick={()=> handleTabChange('scheduling')}>
                    <i className="ri-time-line"></i>
                    <span>Scheduling</span>
                </div>
                <div className={`col-span-1 menu-item ${activeTab === 'users' ? 'active' : ''}`} onClick={()=> handleTabChange('users')}>
                <i className="ri-user-fill"></i>
                    <span>Users</span>
                </div>
            </div>
            <div className='active-content'>
                {activeTab === 'bulletin' && <Bulletin/> }
                {activeTab === 'messaging' && <Messaging/> }
                {activeTab === 'scheduling' && <Scheduling/> }
                {activeTab === 'users' && <Users/> }
            </div>
        </section>
    )
}

export default SidebarWidget