import {FC} from 'react'

export type Props = {
    backgroundImage: string,
    children: React.ReactNode
};

const ChannelHero: FC<Props> = ({ backgroundImage, children}) => {
    const defaultImage:string = '../../channel.png';
    return (

        <>
           <div className="rounded channel-hero overflow-hidden shadow-sm channel-card" style={{background: 'url("'+defaultImage+'"'}}>
               {children}
            </div>
        </>

    )
}

export default ChannelHero;