import { useState } from "react"
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        email: '',
        password: ''
    });

    function isValidEmail(email:string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const submitLogin = () => {
        setLoading(true);

        const validationErrors = {
            email: '',
            password: ''
        }
        if (!isValidEmail(email)) {
            validationErrors.email = 'Please enter a valid email address.';
        }
        if (!password) {
            validationErrors.password = "Please enter your password.";
        }
        

        setTimeout(() => {
            if(!validationErrors.email && !validationErrors.password) { 
                navigate("/account/channels");
            }
            setErrors(validationErrors);
            setLoading(false);
        }, 2000);
       
    }

    return (


        < div >
            <div className="mx-auto ">
                <div className="flex flex-wrap justify-center items-start">

                    <div>
                    <h1 className="mb-6 text-xl md:text-3xl lg:text-3xl font-bold font-heading md:max-w-xl flex" data-config-id="auto-txt-12-1">Login to your account</h1>
                    <form className="w-full max-w-lg auth-form">
                        <div className="flex flex-wrap -mx-3 mb-6">


                            <div className="w-full px-3 mb-6 md:mb-0">
                                <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" placeholder="Email" />
                                {errors.email && <p className="mt-2 mb-2 text-sm text-red-600 dark:text-red-500"> {errors.email}</p>}
                            </div>
                            <div className='w-full px-3 mb-6 md:mb-0'>
                                <input type="password" value={password} onChange={e => setPassword(e.target.value)}  className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" placeholder="Password" />
                                {errors.password && <p className="mt-2 mb-2 text-sm text-red-600 dark:text-red-500"> {errors.password}</p>}
                            </div>

                            <div className='w-full px-3 mb-6 md:mb-3' onClick={submitLogin}>
                                <button disabled={loading} className="py-4 px-6 btn btn-orange w-full text-white font-semibold border border-orange-700 rounded-full focus:ring focus:ring-orange-300 bg-orange-600 hover:bg-orange-700 transition ease-in-out duration-200" type="button">
                                    {loading &&   <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"></path>
                                            </svg>}
                                    {loading? "Submitting..." : "Submit"}
                                </button>
                            </div>
                            
                            <div className='w-full px-3 mb-6 md:mb-0'>
                                <p>Don't have an account? <Link to="/register" className='font-bold text-blue-600'>Register</Link></p>
                            </div>
                        </div>
                    </form>
                    </div>
                    <div className="w-full md:w-1/2 p-8">
                        <img src="../image@2x.png" alt="" data-config-id="auto-img-6-1" />
                    </div>
                </div>
            </div>
        </div >


    )
}

export default Login