import {FC} from 'react'
import { Link } from 'react-router-dom';


const ChannelStats: FC = () => {
    return (

        <>
           <div className="pt-4 pb-2 icon-text flex">
                                <Link to="" className='flex'>
                                <svg id="Icon_Channel_sm" xmlns="http://www.w3.org/2000/svg" width="32" height="31.463" viewBox="0 0 32 31.463">
  <rect id="Guide" width="32" height="31.463" fill="##343A40" opacity="0"/>
  <path id="Union_2" data-name="Union 2" d="M6.954,21.481a.85.85,0,0,1,0-1.69H19.339a.85.85,0,0,1,0,1.69Zm-4.438-2.89A2.672,2.672,0,0,1,0,15.8v-13A2.671,2.671,0,0,1,2.516,0H23.776A2.677,2.677,0,0,1,26.3,2.794v13a2.671,2.671,0,0,1-2.515,2.795Zm-.994-15.8v13a1.053,1.053,0,0,0,.994,1.1H23.776a1.053,1.053,0,0,0,.994-1.1h.006v-13a1.054,1.054,0,0,0-.994-1.1H2.516A1.054,1.054,0,0,0,1.521,2.794ZM3.368,15.568a.693.693,0,0,1-.651-.724V3.741a.693.693,0,0,1,.651-.724H22.923a.693.693,0,0,1,.651.724v11.1a.693.693,0,0,1-.651.724Z" transform="translate(2.852 4.991)" fill="##343A40"/>
</svg>

                                    <span className="inline-block py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">1 channel</span>
                                </Link>
                                <Link to="" className='flex'>
                                                                                            <svg id="Icon_Series_sm" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                            <rect id="Guide" width="32" height="32" fill="##343A40" opacity="0"/>
                                                            <path id="Icon_material-movie-creation" data-name="Icon material-movie-creation" d="M23.8,6l2.6,5.2H22.5L19.9,6H17.3l2.6,5.2H16L13.4,6H10.8l2.6,5.2H9.5L6.9,6H5.6A2.6,2.6,0,0,0,3.013,8.6L3,24.2a2.608,2.608,0,0,0,2.6,2.6H26.4A2.608,2.608,0,0,0,29,24.2V6Z" transform="translate(0 -0.4)" fill="##343A40"/>
                                                            </svg>

                                    <span className="inline-block py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">0 series</span></Link>

                                    <Link to="" className='flex'>
                                    <svg id="Icon_Video_sm" xmlns="http://www.w3.org/2000/svg" width="31.778" height="32.686" viewBox="0 0 31.778 32.686">
  <rect id="Guide" width="31.778" height="32.686" fill="#343A40" opacity="0"/>
  <path id="Icon_awesome-video" data-name="Icon awesome-video" d="M15.676,4.5H2.229A2.229,2.229,0,0,0,0,6.729V20.176A2.229,2.229,0,0,0,2.229,22.4H15.676A2.229,2.229,0,0,0,17.9,20.176V6.729A2.229,2.229,0,0,0,15.676,4.5Zm8.831,1.758L19.4,9.783v7.339l5.11,3.52a1.5,1.5,0,0,0,2.35-1.2V7.461A1.5,1.5,0,0,0,24.507,6.258Z" transform="translate(3 1.5)" fill="##343A40"/>
</svg>


                                    <span className="inline-block py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">0 Episodes</span></Link>

                                    <Link to="" className='flex'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Icon_Users" transform="translate(6 6)">
    <path id="Icon_awesome-user-circle" data-name="Icon awesome-user-circle" d="M10,.563a10,10,0,1,0,10,10A10,10,0,0,0,10,.563Zm0,3.871A3.548,3.548,0,1,1,6.452,7.982,3.548,3.548,0,0,1,10,4.433ZM10,18.3a7.727,7.727,0,0,1-5.907-2.75,4.5,4.5,0,0,1,3.972-2.411.987.987,0,0,1,.286.044A5.339,5.339,0,0,0,10,13.466a5.318,5.318,0,0,0,1.649-.278.987.987,0,0,1,.286-.044,4.5,4.5,0,0,1,3.972,2.411A7.727,7.727,0,0,1,10,18.3Z" transform="translate(0 -0.563)" fill="#343A40"/>
    <g id="Rectangle_779" data-name="Rectangle 779" transform="translate(-6 -6)" fill="#b0b6ba" stroke="#343A40" stroke-width="1" opacity="0">
      <rect width="32" height="32" stroke="#343A40"/>
      <rect x="0.5" y="0.5" width="31" height="31" fill="#343A40"/>
    </g>
  </g>
</svg>


                                    <span className="inline-block py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">0 Seats available</span></Link>
                            </div>

                          
                            </>

    )
}

export default ChannelStats;