import {FC} from 'react';

const Index: FC = () => {
  return (
    <>
        Home
    </>
);
}

export default Index;