import {FC} from 'react'

import { Link } from 'react-router-dom'


export type Event = {
    type: string,
    time: string,
};


export type Schedule = {
    avatar: string,
    name: string,
    channel: string,
    date: string,
    text: string,
    event: Event,
    stats: {
        likes: number,
        reposts: number,
    }
};


export type Props = {
    schedule: Schedule
};

const SchedulingCard: FC<Props> = ({ 
    schedule
}) => {
    return (
            <div className="scheduling-card widget-card">
                <div className='start'>
                    <div className='title'>
                        Event
                    </div>
                    <div className='inline-block flex'>
                        <button className='btn btn-sm btn-success btn-rounded px-2 py-1'>
                            11:00 • 23 August 2022
                        </button>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                            <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                        </svg>
                    </div>
                </div>
                <div className='card-wrappwer'>
                    <div className='avatar'>
                        <img src="https://static.vecteezy.com/system/resources/previews/009/734/564/original/default-avatar-profile-icon-of-social-media-user-vector.jpg"/>
                    </div>
                    <div className='content'>
                        <div className='title'>
                            <b>Username</b>
                            <span>Channel Name</span>
                            <span>1hr ago</span>
                        </div>
                        <div>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor diam nonumy …</p>
                        </div>
                        <div className='grid grid-cols-4 gap-4 stats'>
                            <div className='col-span-1'><i className="ri-heart-fill"></i> 609</div>
                            <div className='col-span-1'> <i className="ri-reply-fill"></i> 120</div>
                            <div className='col-span-1'><i className="ri-send-plane-fill"></i></div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SchedulingCard