import {FC} from 'react'


export type Event = {
    name: string,
    time: string,
};


export type Message = {
    avatar: string,
    channel: string,
    date: string,
    text: string,
    count: number,
};


export type Props = {
    message: Message
};

const BulletinCard: FC<Props> = ({ 
    message
}) => {
    return (
            <div className="messaging-card widget-card">
                <div className='card-wrappwer'>
                    <div className='avatar'>
                        <img src="https://static.vecteezy.com/system/resources/previews/009/734/564/original/default-avatar-profile-icon-of-social-media-user-vector.jpg"/>
                    </div>
                    <div className='content'>
                        <div className='title'>
                            <b>Channel Name</b>
                            <div>Menu</div>
                        </div>
                        <div className='text'>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor diam nonumy …</p>
                            <div className='count'>3</div>
                        </div>
                        <div className='end'>
                            <div className='button-wrapper'>
                                <button className='btn-dark btn btn-sm rounded-lg bg-indigo-600 px-2 py-1 text-white'>Related Item</button>
                                <button className='btn-primary btn btn-sm rounded-lg bg-indigo-600 px-2 py-1 text-white'>Related Item</button>
                            </div>
                            <div className='time'>00:20</div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default BulletinCard