import {FC} from 'react'
import { Link } from 'react-router-dom';
import ChannelHero from '../../../components/ChannelHero';
import ChannelStatsDark from '../../../components/ChannelStatsDark';
import OrgChannelCard from '../../../components/organisation/OrgChannelCard';
const Channel: FC = () => {
    return (

        <>
            <ChannelHero backgroundImage='https://flowbite.com/docs/images/blog/image-1.jpg'>
                <div className='w-screen rounded h-1/2' >
                    <div className="grid grid-cols-1 gap-4 p-12 h-auto channel-hero-content">
                        <div className='title-container'>
                        <h1 className="mb-2 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">Organisation Name</h1>
                        <Link to="" className="btn focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Purple</Link>
                        </div>
                        <p className="mb-4 text-lg font-normal text-gray-300 lg:text-xl">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                        <div className='flex'>
                            <Link to="" className='avatar'>
                                <img className="w-10 h-10 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="Rounded avatar" />
                                <div>
                                    <h6>John Sewa</h6>
                                    <span>Admin</span>
                                </div>
                            </Link>

                            <Link to="" className='avatar px-3'>
                                <img className="w-10 h-10 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-3.jpg" alt="Rounded avatar" />
                                <div>
                                    <h6>Melissa Khumalo</h6>
                                    <span>Admin</span>
                                </div>
                            </Link>
                        </div>
                        <ChannelStatsDark/>
                    </div>
                </div>
            </ChannelHero>

            <div className="channel-list">
                <div className="grid grid-cols-3 gap-4 ">
                    <div className="flex col-span-1 justify-center h-screen">
                        <OrgChannelCard />
                    </div>
                    <div className="flex col-span-1 justify-center h-screen">
                        <OrgChannelCard />
                    </div>
                    <div className="flex col-span-1 justify-center h-screen">
                        <OrgChannelCard />
                    </div>
                </div>
                <Link to="/account/channel-add" className='add-channel-btn'>
                    <i className="ri-add-fill"></i>
                </Link>
            </div>

            

           

        </>
       


    )
}

export default Channel;