import { useCallback, useEffect, useRef, useState } from "react";
const Import = () => {
   
    const { ref, copied, onCopy } = useClipboard({ duration: 4000 });

    return (


        <div className="mb-8 mt-5">
            <p>Copy the open link below and share with your users</p>

            <div className="w-full mb-6 md:mb-0 mt-5">
                <input ref={ref} type="email" value="http://localhost:3000/account/invite-users" className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" placeholder="Email" />
            </div>

            <div className="flex justify-end">
                <button onClick={onCopy} className=" tab-btn btn-dark btn-rounded btn py-2.5 text-sm font-semibold text-white ">{copied ? 'Copied!' : 'Copy'}</button>
            </div>


        </div>

    )
}

export default Import;

const useClipboard = (props:any) => {
    const [copied, setCopied] = useState(false);
    const ref:any = useRef();
    const resetCopy:any = useRef();
  
    const onCopy = useCallback(() => {
      navigator.clipboard
        .writeText(ref.current?.value)
        .then(() => setCopied(true));
    }, [ref]);
  
    useEffect(() => {
      if (copied) {
        resetCopy.current = setTimeout(
          () => setCopied(false),
          props?.duration || 3000,
        );
      }
  
      return () => {
        clearTimeout(resetCopy.current);
      };
    }, [copied, props.duration]);
  
    return { copied, ref, onCopy };
}