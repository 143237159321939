import {FC} from 'react'
import HeroSection from '../components/HeroSection'


const Home: FC = () => {
    return (

        <>
            <HeroSection />

        </>



    )
}

export default Home